import axios from '@axios'

export const fetchFamiliesList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/family/list', { params })
  return res
}

export const fetchFamily = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/family/${id}/details`)
  return res
}

export const deleteFamily = async (id: string): Promise<any> => {
  const res = await axios.delete(`auth/family/${id}`)
  return res
}
