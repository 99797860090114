<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <template #header>
        <h4
          class="d-flex align-items-center mb-0"
        >
          Parents
        </h4>

        <b-button
          v-b-modal.link-parent-modal
          class="ml-1"
          variant="primary"
          :disabled="!$can('update', permissionSubjects.Child) || !$can('update', permissionSubjects.Parent)"
        >
          <span
            class="ml-25 align-middle"
          >Invite Parent By Email</span>
        </b-button>
      </template>

      <b-table
        ref="refParentsTable"
        :items="fetchParentsList"
        :fields="tableColumns"
        :busy="isLoading"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >
        <!-- Loader -->
        <template #table-busy>
          <div class="d-flex justify-content-center">
            <b-spinner
              class="align-middle"
            />
          </div>
        </template>

        <template #row-details>
          <div />
        </template>

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-avatar
            class="mr-1"
            size="32"
            :src="data.item.avatar"
          />
          <b-link
            :to="{ name: 'admin-parent-profile', params: { id: data.item.user_parent_id } }"
          >{{ data.item.full_name }}</b-link>
        </template>

        <!-- Column: email -->
        <template #cell(email)="data">
          {{ data.item.email }}
        </template>

        <!-- Column: is_primary -->
        <template #cell(is_primary)="data">
          <feather-icon
            v-if="data.item.family_member_is_primary"
            id="set_as_default"
            icon="CheckCircleIcon"
            size="16"
            class="align-middle mr-1 text-success"
          />
          <feather-icon
            v-else
            icon="CircleIcon"
            size="16"
            class="align-middle mr-1 cursor-pointer"
            @click="updatePrimaryParent(data.item.family_member_id)"
          />
        </template>

        <!-- Column: relationship type -->
        <template #cell(relationship)="data">
          <div class="d-flex justify-content-between">
            <b-badge
              v-if="!data.detailsShowing"
              variant="light-primary"
              class="cursor-pointer align-content-center mb-1"
              :disabled="!$can('update', permissionSubjects.User)"
              @click="data.toggleDetails"
            >
              {{ data.item.family_member_relationship ? data.item.family_member_relationship : 'Unassigned' }}
            </b-badge>

            <family-relation-selector
              v-else
              :selected-relation="data.item.family_member_relationship"
              :family-member-id="data.item.family_member_id"
              @updated="updateTable"
              @closeEditing="data.toggleDetails"
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :key="'value'"
              :to="{ name: 'admin-parent-profile', params: { id: data.item.user_parent_id } }"
            >
              <span>Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('update', permissionSubjects.Parent) && data.item.family_member_id"
              @click="markAsChild(data.item)"
            >
              <span>Mark as Child</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('read', permissionSubjects.LoginAsParent)"
              @click="loginAs(data.item.id, 'parent')"
            >
              <span>Log In as Parent</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', permissionSubjects.Parent)"
              @click="showDeleteConfirmationModal(data.item)"
            >
              <span>Delete Parent</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <link-parent-modal
      :user-id="inviterToFamilyId"
      :family-id="familyId"
    />

    <confirmation-modal
      v-if="deletableParent"
      :toggle-modal="confirmationModalHide"
      :title="'Are you sure'"
      :message="`Are you sure you want to permanently delete this user (${deletableParent.full_name})? ` +
        'All associated children and applications will also be removed.'"
      :is-loading="isUserDeleteLoading"
      @close-modal="confirmationModalHide = !confirmationModalHide"
      @confirm="deleteItem"
    />
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BLink,
  BAvatar,
  BButton,
  BDropdownItem,
  BDropdown,
  BBadge,
  BSpinner,
} from 'bootstrap-vue'
import { loginAs } from '@core/mixins/loginAs'
import LinkParentModal from '@/views/components/parents-list/LinkParentModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import { appParentsList } from '@/store/modules/app-parents/app-parenst-list'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import FamilyRelationSelector from '@/views/components/parents-list/FamilyRelationSelector.vue'
import { appFamilyDetails } from '@/store/modules/app-families/app-family-details'

export default {
  components: {
    FamilyRelationSelector,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCard,
    BTable,
    BLink,
    BAvatar,
    BButton,
    BSpinner,
    LinkParentModal,
    ConfirmationModal,
  },
  mixins: [loginAs],
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
    familyId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      items: [],
      deletableParent: null,
      isLoading: false,
      confirmationModalHide: false,
      isUserDeleteLoading: false,
      selectedRelation: null,
      permissionSubjects,
    }
  },
  computed: {
    tableColumns() {
      return appParentsList.getters.getTableColumns
    },
    sortBy() {
      return appParentsList.getters.getSortBy
    },
    isSortDirDesc() {
      return appParentsList.getters.getIsSortDirDesc
    },
    inviterToFamilyId() {
      return this.userData ? this.userData.id : null
    },
    reloadData() {
      return appFamilyDetails.getters.reloadData
    },
  },
  watch: {
    reloadData() {
      this.updateTable()
    },
  },
  methods: {
    async fetchParentsList() {
      return appParentsList.actions.fetchParentsList(this.familyId)
    },
    showDeleteConfirmationModal(parent) {
      this.deletableParent = parent
      this.$nextTick(() => {
        this.$bvModal.show('confirmation')
      })
    },
    async deleteItem() {
      if (this.deletableParent.id) {
        try {
          this.isUserDeleteLoading = true
          await appParentsList.actions.deleteParent(this.deletableParent.user_parent_id)
            .then(() => {
              this.updateTable()
            })
          this.deletableParent = null
        } finally {
          this.isUserDeleteLoading = false
        }
      }

      this.confirmationModalHide = !this.confirmationModalHide
    },
    updateTable() {
      this.$refs.refParentsTable.refresh()
    },
    async updatePrimaryParent(memberId) {
      try {
        const queryParams = {
          id: memberId,
          is_primary: true,
        }
        this.isLoading = true

        appParentsList.actions.updFamilyMember(queryParams)
        this.$emit('updated')
      } finally {
        this.isLoading = false
        this.updateTable()
      }
    },
    async markAsChild(student) {
      try {
        const queryParams = {
          id: student.family_member_id,
          is_parent: false,
        }
        this.isLoading = true

        appParentsList.actions.updFamilyMember(queryParams)
      } finally {
        this.isLoading = false
        this.updateTable()
        appFamilyDetails.mutations.RELOAD_DATA()
      }
    },
  },
}
</script>

<style lang="scss">

</style>
