import axios from '@axios'

/* eslint-disable import/prefer-default-export */
export const fetchUsersList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/users/', { params })
  return res
}

export const deleteParent = async (id: string): Promise<any> => {
  const res = await axios.delete(`auth/parents/${id}`)
  return res
}
